#unique-fields {
  .unique-fields {
    position: relative;
  }

  .unique-fields__item {
    margin-bottom: 10px;
  }

  .unique-fields__link {
    color: var(--white);
    display: block;
    padding: 10px 12px 11px;
    background-color: var(--white-obj);
    box-shadow: var(--box-shadow);
    border-radius: var(--border-radius);
    transition: var(--transition);
    border: 1px solid transparent;
    transition: var(--transition);

    &:hover {
      transform: scale(1.01);
    }
  }
}
