.body {
  margin-bottom: 30px;
}

.block {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.title {
  margin-right: 15px;
}

.input {
  width: 54px;
}

.buttons {
  text-align: center;
  button {
    width: fit-content;
    margin: 0 7.5px;
  }
}

.checkbox {
  display: none;
  &__wrapper {
    height: 24px;
  }
  &__label {
    position: relative;
    cursor: pointer;
    box-shadow: 1px 7px 11px inset rgba(0, 0, 0, 0.25);
    background-color: var(--gray);
    padding: 12px;
    width: 45px;
    display: inline-block;
    border-radius: 50px;
    transition: var(--transition);
    span {
      width: 100%;
      border-radius: 10px;
      position: absolute;
      z-index: 1;
      transform: translateY(-50%);
    }
    span:after {
      content: '';
      width: 20px;
      height: 20px;
      border-radius: 50%;
      position: absolute;
      left: -10px;
      top: -10px;
      background-color: #fff;
      box-shadow: -3px 3px 8px rgba(0, 0, 0, 0.4);
      transition: var(--transition);
    }
  }
  &:checked ~ .checkbox__label {
    background-color: var(--blue);
    span:after {
      left: 11px;
    }
  }
}
