.video__title {
	margin-bottom: 15px;
}

.photo__title {
	margin-top: 15px;
	margin-bottom: 15px;
}

.video {
  width: 180px;
  height: 136px;
	position: relative;
	margin-bottom: 10px;
	cursor: pointer;

  img {
    width: 100%;
    height: inherit;
    border-radius: var(--border-radius);
  }

	.video__icon {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
}