@import '../../../../../assets/styles/static/mixins';

.video__title {
  margin-bottom: 20px;
}

.photo__content {
  margin-bottom: 15px;
}

.photo__content,
.video__content {
  @include flex(flex-start);
  flex-wrap: wrap;
}

.video__content {
  align-items: start;
}

.admin__videoPhoto {
  width: 368px;
  text-align: center;
  margin-bottom: 15px;
}

.imageWithFilter {
  width: 368px;
  height: auto;
  margin-bottom: 15px;
  cursor: pointer;

  &__wrapper {
    &:not(:last-child) {
      margin-right: 15px;
    }
  }

  img {
    width: 100%;
    height: inherit;
    border-radius: var(--border-radius);
    filter: drop-shadow(0px 0px 4px rgba(40, 130, 181, 0.4));
  }
}

.addPhoto__btn {
  margin-bottom: 15px;
}

.addVideo__input {
  min-width: 200px;
  margin-bottom: 15px;
  input {
    background-color: var(--white-obj);
  }
}
