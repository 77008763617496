:root {
  // Colors
  --white-bg: #fbfbfb;
  --white-obj: #ffffff;
  --black-bg: #2c2c2c;
  --black-obj: #3d3d3d;

  --blocked: #f3f3f3;

  --gray: #707070;
  --gray-light: rgba(61, 61, 61, 0.55);
  --gray-dark: #4c4c4c;
  --blue: #2881b5;
  --blue-light: #459ed0;
  --red: #eb5757;
  --red-light: #f07878;
  --selection: rgb(154 244 154);

  // Fonts
  --R_Regular: 'Roboto-Regular';
  --R_Medium: 'Roboto-Medium';
  --R_Light: 'Roboto-Light';

  // Containers
  --container: 660px;
  --container-big: 1120px;
  --container-greatest: 1380px;

  // Transitions
  --transition: all linear 0.2s;

  // Shadow
  --box-shadow: 0px 0px 4px rgba(40, 130, 181, 0.4);

  // Radius
  --border-radius: 3px;

  // Border
  --border: 1px solid #2881b5;
}
