#dashboard {
	width: 100%;
	height: 100vh;
	position: relative;
	@include flex();

	.menu-bg {
		position: absolute;
		top: 0;
		bottom: 0;
		right: 0;
		left: 0;	
		z-index: -1;
		width: 100%;
		height: 100%;
		background-image: url("../../images/menu-bg.jpg");
		background-size: cover;
		background-repeat: no-repeat;
	}

	.dashboard__inner {
		padding: 50px;
		border-radius: var(--border-radius);
		background-color: var(--white-obj);
		text-align: center;
		max-width: 382px;
		width: 100%;
	}

	.dashboard__list {
		margin-top: 30px;
		&-item {
			margin-top: 20px;
		}
	}
}