.select {
	margin-bottom: 20px;
	position: absolute;
	left: 50px;
	right: 50px;
}

.btn {
	padding-top: 58px;
	text-align: center;
	button {
		width: 300px;
	}
}