#script {
	padding-top: 118px;
	padding-bottom: 50px;
	position: relative;

	.script__blocks {
		@include flex(center, flex-start);
	}

	.script__body {
		max-width: 745px;
		width: 100%;
	}

	.script__title {
		display: inline-block;
		padding-left: 20px;
		text-transform: uppercase;
	}

	.script__btnBack {
		background-color: transparent;
		border: none;
		box-shadow: none;
		width: fit-content;
		height: auto;
		margin-bottom: 30px;
		cursor: pointer;
	}

	.script__objections {
		&-title {
			padding: 20px;
			background-color: var(--white-obj);
			box-shadow: 0px 0px 4px rgba(40, 130, 181, 0.4);
			border-radius: 3px;
			margin-bottom: 15px;
		}
		&-btn {
			margin-top: 15px;
		}
		margin-left: 15px;
		max-width: 350px;
		width: 100%;
	}
}