.form {
	max-width: 285px;
	width: 100%;
	margin-top: 25px;
}
.field:nth-child(2) {
	margin: 15px 0 25px;
}
.input input {
	color: #4C4C4C !important;
}

.input input::placeholder {
	color: #707070 !important;
}