.settings {
  display: flex;
  align-items: center;
  margin-bottom: 35px;
}

.days {
  margin-left: 15px;
}

.input {
  width: 54px;
}
