@import "../../../../assets/styles/static/mixins";

.button {
	height: 40px;
	max-width: 640px;
	width: 100%;
	padding: 0 21.5px 0 15px;
	color: #FFFFFF;
	background-color: var(--blue);
	border-radius: var(--border-radius);
	transition: var(--transition);
	@include flex(space-between);
	margin-bottom: 15px;
	&:hover {
		background-color: var(--blue-light);
	}
}