@import '../../../assets/styles/static/mixins';

.accordion {
  width: 100%;
  background-color: var(--white-obj);
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
  margin-bottom: 15px;
  transition: var(--transition);

  &:hover {
    transform: scale(1.01);
  }
}

.cap {
  @include flex(space-between);
  padding: 11px 20px 10px 15px;
  cursor: pointer;

  .title {
    user-select: none;
    padding-right: 10px;
  }

  .title,
  svg {
    transition: var(--transition);
  }

  .btn__icons {
    @include flex();
    .dnd__icon {
      margin-right: 15px;
      svg {
        vertical-align: middle;
      }
    }
    .arrow__icon {
      transition: var(--transition);
    }
  }

  &.active {
    .title {
      font-family: var(--R_Medium), sans-serif;
      color: var(--gray-dark);
    }
    .arrow__icon {
      transform: rotate(180deg);
    }
  }
}

.content {
  font-family: var(--R_Light), sans-serif;
  padding: 5px 72px 15px 15px;
  position: relative;
}

.functions {
  position: absolute;
  top: 0;
  right: 20px;
  z-index: 5;
  display: flex;
  list-style: none !important;
  margin: 0 !important;
  .function {
    cursor: pointer;
    list-style-type: none;
    margin: 0;
  }
  .remove {
    margin-left: 15px;
  }
  .edit svg path {
    fill: var(--blue);
  }
  .remove svg path {
    stroke: var(--red);
  }
  svg {
    vertical-align: middle;
  }
}
