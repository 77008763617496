.themeInput {
  display: none;
  &__label {
    position: relative;
    cursor: pointer;
    box-shadow: 1px 7px 11px inset rgba(0, 0, 0, 0.25);
    background-color: var(--blue);
    padding: 12px;
    width: 45px;
    display: inline-block;
    border-radius: 50px;
    &::before,
    &::after {
      font-size: 1em;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
    &::before {
      content: '\263C';
      right: -4px;
      top: 9px;
      width: 11px;
      height: 11px;
      margin-right: 10px;
      color: #fff;
    }
    &::after {
      content: '\263E';
      left: -5px;
			bottom: -6px;
      margin-left: 10px;
      color: #2a3742;
    }
    span {
      width: 100%;
      border-radius: 10px;
      position: absolute;
			z-index: 1;
      transform: translateY(-50%);
    }
    span:after {
      content: '';
      width: 20px;
      height: 20px;
      border-radius: 50%;
      position: absolute;
      left: -10px;
      top: -10px;
      background-color: #fff;
      box-shadow: -3px 3px 8px rgba(0, 0, 0, 0.4);
      transition: var(--transition);
    }
  }
  &:checked ~ .themeInput__label {
    &::before {
      color: #2a3742;
    }
    &::after {
      color: #fff;
    }
    span:after {
      left: 11px;
    }
  }
}
