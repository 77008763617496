.pagination {
  display: flex;
  align-items: center;
}

.item {
  margin: 0 2px;
  &:not(.active):hover a {
    background-color: rgba(#2881b5, 0.1);
  }
}

.link {
  color: var(--gray-dark);
  line-height: 24px;
  width: 44px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  cursor: pointer;
  transition: var(--transition);
  user-select: none;
}

.active a {
  background-color: var(--blue);
  color: #fff;
}

.break a,
.previous a,
.next a {
  width: 44px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.break a {
  color: var(--gray);
}

.previous a,
.next a {
  color: var(--blue);
  transition: var(--transition);
  &:hover {
    transform: scale(1.2);
  }
}

.previous {
  margin-right: 2px;
  user-select: none;
}

.next {
  margin-left: 2px;
  user-select: none;
}

.disabled {
  color: var(--gray) !important;
}
