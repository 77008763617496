.wrapper {
  display: flex;
  align-items: center;
}

.title {
  color: var(--gray-dark);
}

.count {
  color: var(--gray-light);
}
