@import '../../../../assets/styles/static/mixins';

.body {
  position: relative;
}

.form {
  margin-top: 15px;
  margin-bottom: 15px;
}

.field {
  margin-top: 15px;
  position: relative;
  .name input {
    background-color: var(--white-obj);  
  }
	.name input, .name input::placeholder {
		color: var(--gray-dark);
	}
	.text input, .text input::placeholder {
		font-family: var(--R_Light), sans-serif;
	}
}

.btns {
  margin-top: 15px;
  @include flex(flex-start);
  button {
    width: fit-content;
  }
  button:first-child {
    margin-right: 15px;
  }
}
