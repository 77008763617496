@import '../../../../assets/styles/static/mixins';

.modal {
	max-width: 325px;
	position: fixed;
	z-index: 3;
	padding: 13px 15px;
	background-color: var(--blue);
	border-radius: var(--border-radius);
	&.bottom-right {
		bottom: 15px;
		right: 15px;
	}
	&.bottom-left {
		bottom: 15px;
		right: 15px;
	}
	&.top-right {
		top: 103px;
		right: 15px;
	}
	&.top-left {
		top: 103px;
		left: 15px;
	}
	&.red {
		background-color: #FF6B6B;
	}
}

.header {
	@include flex(space-between, center);
}

.title {
	color: #fff;
}

.exclamationIcon {
	padding-right: 15px;
	svg {
		width: 36px;
		height: 36px;
		vertical-align: middle;
		path {
			fill: #fff;
		}
	}
}

.closeBtn {
	padding-left: 15px;
}

.text {
	font-size: 14px;
	margin-top: 5px;
}

.close {
	cursor: pointer;
	&__icon {
		vertical-align: middle;
		width: 10px;
		height: 10px;
		path{
			transition: var(--transition);
			fill: #EBEBEB;
		}
	}
	&:hover &__icon path{
		fill: rgba(#000, 0.5);
	}
}