/* flex settings */
@mixin flex($jc: center, $ai: center) {
  display: flex;
  justify-content: $jc;
  align-items: $ai;
}

%flexColumn {
  display: flex;
  flex-direction: column;
}

/* media query settings */
@mixin lg-desktop {
  @media screen and (max-width: 1600px) {
    @content;
  }
}

@mixin desktop {
  @media screen and (max-width: 1200px) {
    @content;
  }
}

@mixin desktop-sm {
  @media screen and (max-width: 992px) {
    @content;
  }
}

@mixin table {
  @media screen and (max-width: 768px) {
    @content;
  }
}
