#create-script {
  .create-script__create {
    margin-bottom: 15px;
  }
  .create-script__btns {
    @include flex(flex-start);
    button {
      width: fit-content;
    }
    button:first-child {
      margin-right: 15px;
    }
  }
}