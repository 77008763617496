.textarea {
	width: 100%;
	text-align: left;
	background-color: var(--white-obj);
	box-shadow: var(--box-shadow);
	border-radius: var(--border-radius);
	padding: 11px 12px 10px;
}

.error {
	border: 1px solid var(--red);
	border-radius: var(--border-radius);
}