@import '../../../../assets/styles/static/mixins';

.form {
	margin-top: 15px;
	margin-bottom: 15px;
}

.field {
	margin-top: 15px;
	position: relative;
	input {
		background-color: var(--white-obj);
	}
	.name input, .name input::placeholder {
		color: var(--gray-dark);
	}
}

.main {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 15px;
	margin-bottom: 15px;
}

.btns {
	@include flex(flex-start);
	margin-top: 15px;
	button {
		width: fit-content;
	}
	button:first-child {
		margin-right: 15px;
	}
}