@import '../../../../assets/styles/static/mixins';

.photo {
  width: 295px;
  height: auto;
  cursor: pointer;
  position: relative;
  img {
    border-radius: var(--border-radius);
    border: var(--border);
    height: inherit;
  }
}

.main {
  @include flex(flex-start, flex-start);
	margin-bottom: 15px;
}

.list {
  max-width: 441px;
  width: 100%;
  padding-left: 15px;
}

.new__price {
  font-size: 20px;
}

.title {
	font-family: var(--R_Medium), sans-serif;
}

.list__item {
  margin-bottom: 15px;
}

.description__title {
  font-size: 20px;
  margin-bottom: 10px;
}