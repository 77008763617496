#all-scripts {
  .all-scripts__pagination {
    display: flex;
    justify-content: center;
    &--hide {
      height: 0;
      opacity: 0;
      pointer-events: none;
      visibility: hidden;
    }
  }
}
