@import '../../../../assets/styles/static/mixins';

.item {
  @include flex(flex-start);
  max-width: 640px;
  width: 100%;
  background-color: var(--white-obj);
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
  cursor: pointer;
  margin-bottom: 15px;
	position: relative;
	min-height: 136px;
	transition: var(--transition);
	user-select: none;

	&:hover {
    transform: scale(1.01);
  }

	&.blocked {
		background-color: var(--blocked);
		cursor: default;
	}

	.wrapper {
		max-width: 180px;
    width: 100%;
		height: 136px;
	}

  img {
    border-radius: var(--border-radius);
    border: var(--border);
    height: inherit;
  }

  .info {
    padding: 20px 20px 10px;
    width: 100%;
  }

	.title {
		text-transform: uppercase;
		margin-bottom: 10px;
    padding-right: 150px;
	}

  .list {
    &__item {
      font-family: var(--R_Medium), sans-serif;
      color: var(--gray-dark);
    }
    &__item:not(:last-child) {
      margin-bottom: 10px;
    }
  }

	.add_project {
		position: absolute;
		bottom: 10px;
		right: 20px;
	}

	.functions {
		position: absolute;
		top: 20px;
		right: 20px;
		@include flex();
		.function:not(first-child) {
			margin-left: 20px;
		}
		.dropDownIcon  {
			path {
				stroke: var(--blue);
			}
		}
		.detachedIcon {
			path {
				fill: var(--blue);
			}
		}
    .detachedIcon.blocked {
			path {
				fill: var(--gray);
			}
    }
	}

	.redIcon {
		path {
			stroke: var(--red);
		}
	}

	.blueIcon {
		path {
			fill: var(--blue);
		}
	}

}
