@import '../../../../assets/styles/static/mixins';

.form {
  margin-bottom: 15px;
}

.field {
  position: relative;
	margin-bottom: 15px;
	&__language {
		position: absolute !important;
		z-index: 5;
		top: 50%;
		right: 15px;
		transform: translateY(-50%);
	}
	.title input {
		padding-right: 70px;
		background-color: var(--white-obj);
	}
	.title input, .title input::placeholder {
		color: var(--gray-dark);
	}
}

.btns {
  @include flex(flex-start);
  button {
    width: fit-content;
  }
  button:first-child {
    margin-right: 15px;
  }
}