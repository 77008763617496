@font-face {
	font-family: 'Roboto-Regular';
	src: url('../../fonts/Roboto/Roboto-Regular.eot');
	src: local('Roboto'), local('Roboto-Regular'),
			url('../../fonts/Roboto/Roboto-Regular.eot?#iefix') format('embedded-opentype'),
			url('../../fonts/Roboto/Roboto-Regular.woff2') format('woff2'),
			url('../../fonts/Roboto/Roboto-Regular.woff') format('woff'),
			url('../../fonts/Roboto/Roboto-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Roboto-Medium';
	src: url('../../fonts/Roboto/Roboto-Medium.eot');
	src: local('Roboto Medium'), local('Roboto-Medium'),
			url('../../fonts/Roboto/Roboto-Medium.eot?#iefix') format('embedded-opentype'),
			url('../../fonts/Roboto/Roboto-Medium.woff2') format('woff2'),
			url('../../fonts/Roboto/Roboto-Medium.woff') format('woff'),
			url('../../fonts/Roboto/Roboto-Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Roboto-Light';
	src: url('../../fonts/Roboto/Roboto-Light.eot');
	src: local('Roboto Light'), local('Roboto-Light'),
			url('../../fonts/Roboto/Roboto-Light.eot?#iefix') format('embedded-opentype'),
			url('../../fonts/Roboto/Roboto-Light.woff2') format('woff2'),
			url('../../fonts/Roboto/Roboto-Light.woff') format('woff'),
			url('../../fonts/Roboto/Roboto-Light.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}

