@import '../../../../assets/styles/static/mixins';

.item {
  @include flex(flex-start);
  max-width: 640px;
  width: 100%;
  background-color: var(--white-obj);
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
  cursor: pointer;
  margin-bottom: 15px;
  min-height: 136px;
  position: relative;
  transition: var(--transition);
	
	&:hover {
    transform: scale(1.01);
  }

  .img__wrapper {
    max-width: 180px;
    width: 100%;
    height: 136px;
    position: relative;
    img {
      border-radius: var(--border-radius);
      border: var(--border);
      height: inherit;
    }
    .video__icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .info {
    padding: 20px;
    width: 100%;
  }

  .title {
    margin-bottom: 20px;
    padding-right: 60px;
  }
  
  .text {
    font-family: var(--R_Light), sans-serif;
  }

  .functions {
    position: absolute;
    top: 20px;
    right: 20px;
    @include flex();
    .function:not(first-child) {
      margin-left: 20px;
    }
  }

  .redIcon {
    path {
      stroke: var(--red);
    }
  }
  
  .blueIcon {
    path {
      fill: var(--blue);
    }
  }

}