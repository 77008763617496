@import "../../../../assets/styles/static/mixins";

.item {
	@include flex(space-between);
	max-width: 640px;
	width: 100%;
	padding: 10px 12px 11px;
	background-color: var(--white-obj);
	box-shadow: var(--box-shadow);
	border-radius: var(--border-radius);
	transition: var(--transition);
	border: 1px solid transparent;
	cursor: pointer;
	margin-bottom: 10px;
	transition: var(--transition);

	&:hover {
    transform: scale(1.01);
  }

	&.blocked {
		background-color: var(--blocked);
		cursor: default;
	}

	.main {
		user-select: none;
	}

	.main, .functions {
		@include flex();
	}

	.check {
		display: none;
	}
	
	&.selected {
		border: var(--border);
		.title {
			color: var(--blue);
			margin-left: 15px;
		}
		.check {
			display: block;
		}
	}

	svg {
		vertical-align: middle;
	}

	.function {
		margin-left: 20px;
		transition: var(--transition);
		svg.blocked {
			path {
				fill: var(--blue);
			}
		}
	}

	.arrow {
		width: 10px;
		transform: rotate(180deg);
	}

}