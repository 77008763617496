.icon {
  path {
    fill: var(--gray);
    transition: var(--transition);
  }
  &:hover path {
    fill: var(--blue);
  }
}

.icon_spec {
  path {
    stroke: var(--gray);
    transition: var(--transition);
  }
  &:hover path {
    stroke: var(--blue);
  }
}
