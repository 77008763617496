#forbidden {
  .forbidden__title {
    margin: 100px 0;
    color: var(--red);
    width: 100%;
    font-size: 50px;
    line-height: 57px;
    opacity: 0.5;
  }
}
