.wrapper {
  background-color: var(--white-obj);
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
  position: relative;
}

.error {
  border: 1px solid var(--red);
}

.toolbar {
  border: none;
  border-bottom: 1px solid #70707029;
  margin-bottom: 0;
  background-color: var(--white-obj);
  color: #4C4C4C;
  img { 
    width: auto;
  }
  button {
    text-align: center;
  }
}

.editor {
  padding: 15px 12px;
  font-family: var(--R_Light);

  a {
    color: var(--gray);
  }

  span[style*="font-weight: bold;"] {
    font-family: var(--R_Medium) !important;
  }
}
