.slider {
  position: relative;
	width: 100%;
  &::before {
    content: '';
    pointer-events: none;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 34px;
    background: linear-gradient(270deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
    z-index: 3;
    opacity: 1;
    transition: var(--transition);
  }
  & > div {
    height: 136px !important;
  }
  &.dark::before {
    background: linear-gradient(270deg, #3D3D3D 0%, rgba(61, 61, 61, 0) 100%);
  }
  &.ending {
    &::before {
      opacity: 0;
      visibility: hidden;
    }
  }
}

.swiperSlide {
  width: auto !important;
  margin-right: 15px;
}

.swiperSlide:not(:last-child) {
  margin-right: 15px;
}


.image {
  width: 180px;
  height: 136px;

  &__wrapper {
    &:not(:last-child) {
      margin-right: 15px;
    }
  }

  img {
    width: 100%;
    height: inherit;
    border-radius: var(--border-radius);
  }
}