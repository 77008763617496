#login {
  height: 100vh;
  background-color: #f5f5f5;
  @include flex();

  .login__inner {
    box-shadow: var(--box-shadow);
    border-radius: var(--border-radius);
    background-color: var(--black-bg);
    max-width: 437px;
    width: 100%;
    text-align: center;
    margin: 0 auto;
    padding: 50px 76px;
  }
}
