@import '../../../assets/styles/static/mixins';

.image {
  width: 180px;
  height: 136px;
  position: relative;
  cursor: pointer;

  &__wrapper {
    &:not(:last-child) {
      margin-right: 15px;
    }
  }

  img {
    width: 100%;
    height: inherit;
    border-radius: var(--border-radius);
  }

  .delete {
    position: absolute;
    z-index: 2;
    bottom: 10px;
    right: 10px;
    border-radius: var(--border-radius);
    @include flex();
  }

  .deleteIcon {
    path {
      stroke: var(--red);
    }
  }
}

.modalContent {
  max-width: 1100px;
  padding: 0 5px 5px 10px;
}

.modalBody {
  padding: 0;
}
