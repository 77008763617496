@import '../../../../assets/styles/static/mixins';

.item {
  @include flex(flex-start);
  max-width: 640px;
  width: 100%;
  background-color: var(--white-obj);
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
  cursor: pointer;
  margin: 0 auto 15px;
  position: relative;
  min-height: 136px;
  transition: var(--transition);

  &:hover {
    transform: scale(1.01);
  }

  .wrapper {
    max-width: 136px;
    width: 100%;
    height: 136px;
  }

  img {
    border-radius: var(--border-radius);
    border: var(--border);
    height: inherit;
  }

  .info {
    padding: 20px 20px 10px;
    width: 100%;
  }

  .title {
    margin-bottom: 10px;
  }

  .list {
    &__item {
      font-family: var(--R_Medium), sans-serif;
      color: var(--gray-dark);
    }
    &__item:not(:last-child) {
      margin-bottom: 10px;
    }
  }

  .date {
    position: absolute;
    bottom: 35px;
    right: 20px;
    &__title {
      display: flex;
      color: var(--red);
      p {
        margin-right: 5px;
      }
    }
    &__text {
      position: absolute;
      bottom: 26px;
      right: -10px;
      z-index: 1000;
      width: 350px;
      background-color: var(--white-bg);
      border-radius: var(--border-radius);
      box-shadow: var(--box-shadow);
      padding: 15px;
      opacity: 0;
      overflow: hidden;
      pointer-events: none;
      transition: var(--transition);
    }
    &:hover .date__text {
      opacity: 1;
      overflow: visible;
      pointer-events: all;
    }
  }

  .addProject {
    position: absolute;
    bottom: 10px;
    right: 20px;
  }

  .functions {
    position: absolute;
    top: 20px;
    right: 20px;
    @include flex();
    .function:not(first-child) {
      margin-left: 20px;
    }
  }

  .redIcon {
    path {
      stroke: var(--red);
    }
  }

  .blueIcon {
    path {
      fill: var(--blue);
    }
  }
}
