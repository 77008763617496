@import '../../../../assets/styles/static/mixins';

.form {
  margin-bottom: 15px;
}

.field {
  position: relative;
  .name input {
    background-color: var(--white-obj);
  }
  .name input,
  .name input::placeholder {
    color: var(--gray-dark);
  }
  &:not(:first-child) {
    margin-top: 15px;
  }
}

.order {
  max-width: fit-content;
  &__title {
    margin-right: 15px;
  }
  &__field {
    display: flex;
    align-items: center;
  }
}

.btns {
  margin-top: 15px;
  @include flex(flex-start);
  button {
    width: fit-content;
  }
  button {
    margin-right: 15px;
  }
}

.addButton__wrapper {
  position: relative;
  .help__text {
    width: auto;
    white-space: nowrap;
    bottom: 45px;
  }
  .addButton:disabled:hover + .help__text {
    opacity: 1;
    overflow: visible;
    pointer-events: all;
  }
}

.checkbox__wrapper {
  @include flex();
}
.checkbox__values label {
  color: var(--gray);
  margin-right: 15px;
}

.help {
  position: relative;
  svg {
    vertical-align: sub;
  }
  &__text {
    position: absolute;
    bottom: 26px;
    right: -100px;
    z-index: 1000;
    width: 300px;
    background-color: var(--white-bg);
    border-radius: var(--border-radius);
    box-shadow: var(--box-shadow);
    padding: 15px;
    opacity: 0;
    overflow: hidden;
    pointer-events: none;
    transition: var(--transition);
  }
  &:hover .help__text {
    opacity: 1;
    overflow: visible;
    pointer-events: all;
  }
}
