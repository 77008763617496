.datepicker {
  cursor: pointer;
  font-family: var(--R_Medium), sans-serif;
  width: 122px;
  height: 34px;
  color: rgba(61, 61, 61, 1);
  padding: 7px 10px;
  border: 1px solid rgba(215, 230, 239, 1);
  background: linear-gradient(0deg, #d7e6ef, #d7e6ef), linear-gradient(0deg, #e4f0f6, #e4f0f6);
  border-radius: var(--border-radius);
}
