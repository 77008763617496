.slider {
  background-color: var(--white-obj);
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
  padding: 15px;
  margin-bottom: 15px;
  position: relative;
  &::before {
    content: '';
    pointer-events: none;
    position: absolute;
    top: 35px;
    right: 14px;
    height: calc(100% - 35px);
    width: 34px;
    background: linear-gradient(270deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
    z-index: 3;
    opacity: 1;
    transition: var(--transition);
  }
  & > div {
    padding-top: 35px;
    height: 252px;
  }
  &.dark::before {
    background: linear-gradient(270deg, #3d3d3d 0%, rgba(61, 61, 61, 0) 100%);
  }
  &.ending {
    &::before {
      opacity: 0;
      visibility: hidden;
    }
  }
}

.swiperSlide {
  width: 180px !important;
  margin-right: 15px;
}

.swiperSlide:not(:last-child) {
  margin-right: 15px;
}

.arrow__prev,
.arrow__next {
  top: 11px;
  width: 38px;
  height: 20px;
  border: var(--border);
  border-radius: var(--border-radius);
}

.arrow__next {
  right: 150px;
}

.arrow__prev {
  left: auto;
  right: 180px;
  margin-right: 20px;
}

.checkAll {
  position: absolute;
  top: 0;
  right: 20px;
  color: var(--blue);
}
