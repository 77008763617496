@import '../../../../assets/styles/static/mixins';

.form {
  margin-top: 15px;
}

.field {
  margin-top: 15px;
  position: relative;
  input {
    background-color: var(--white-obj);
  }
  .login input,
  .login input::placeholder,
  .password input,
  .password input::placeholder {
    color: var(--gray-dark);
  }
  .password input {
    padding-right: 40px;
  }
}

.fullName {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 15px;
}

.mac {
  @include flex(flex-start);
  .mac__input {
    margin: 0 15px;
  }
  .mac__delete {
    color: var(--red);
    transition: var(--transition);
    &:hover {
      color: var(--red-light);
    }
  }
}

.emptyRole {
  div {
    border-color: var(--red) !important;
  }
}

.generate {
  position: absolute;
  top: 50%;
  right: 15px;
  height: 18px;
  transform: translateY(-50%);
  cursor: pointer;
}

.btns {
  margin-top: 15px;
  @include flex(flex-start);
  button {
    width: fit-content;
  }
  button:first-child {
    margin-right: 15px;
  }
}
