.button {
  height: 136px;
  width: 180px;
  background-color: var(--white-obj);
  border-radius: var(--border-radius);
  border: var(--border);
  text-align: center;
  position: relative;
  input {
    position: absolute;
    opacity: 0;
    overflow: hidden;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    &.inputDisabled {
      pointer-events: none;
    }
  }
  span {
    font-size: 56px;
    font-family: var(--R_Medium), sans-serif;
    line-height: 27px;
    color: var(--blue);
    opacity: 0.5;
    pointer-events: none;
  }
}
