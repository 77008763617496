.table {
  width: 100%;
  tr {
    border: 1px solid rgba(215, 230, 239, 1);
  }
  th {
    font-family: var(--R_Medium), sans-serif;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.04em;
    padding: 15.5px 10px;
    text-transform: uppercase;
    color: var(--gray-light);
    background-color: var(--white-bg);
    user-select: none;
  }
  td {
    font-size: 14px;
    line-height: 17px;
    padding: 12px 10px;
    vertical-align: middle;
    color: var(--gray-dark);
    background-color: var(--white-obj);
  }
  td:first-child {
    width: 240px;
  }
  tbody {
    position: relative;
  }
}

.specFlex {
  display: flex;
  align-items: center;
}

.orderIcons {
  display: flex;
  flex-direction: column;
  margin-left: 6px;
  cursor: pointer;
}

.scriptName {
  color: var(--blue);
}

.orderIcon {
  path {
    fill: var(--gray-light);
  }
  &.active {
    path {
      fill: var(--gray-dark);
    }
  }
}

.orderBottom {
  margin-top: 2px;
  transform: rotate(180deg);
}

.type span {
  word-break: normal;
  text-transform: uppercase;
  color: rgba(61, 61, 61, 0.8);
  background-color: rgba(228, 240, 246, 1);
  padding: 8px;
  border-radius: var(--border-radius);
}

.type.green span {
  background-color: rgb(205, 255, 205);
}

.type.red span {
  background-color: #ffcfcf;
}

.type.yellow span {
  background-color: rgb(255, 238, 172);
}

.type.violet span {
  background-color: #f1cfff;
}

.more__btn.viewed {
  color: var(--red-light);
  .arrow path {
    fill: var(--red-light);
    stroke: var(--red-light);
  }
  &:hover .arrow path {
    fill: var(--red-light);
    stroke: var(--red-light);
  }
}

.more__btn:hover .arrow {
  transform: translateX(20%);
  path {
    fill: var(--blue-light);
  }
}

.arrow {
  margin-left: 5px;
  vertical-align: middle;
  transition: var(--transition);
  path {
    transition: var(--transition);
  }
}
