@import '../../../../assets/styles/static/mixins';

.images {
  @include flex(flex-start);
  margin-bottom: 15px;
}

.image {
  width: 180px;
  height: 136px;

  &__wrapper {
    &:not(:last-child) {
      margin-right: 15px;
    }
  }

  img {
    width: 100%;
    height: inherit;
    border-radius: var(--border-radius);
  }
}

.content {
  margin-bottom: 35px;
  position: relative;
  user-select: none;
  &__title {
    margin-bottom: 10px;
    user-select: text;
  }
  &__text {
    font-family: var(--R_Light);
    user-select: text;
  }
  &__title.spec,
  &__text.spec {
    background-color: var(--white-obj);
    box-shadow: var(--box-shadow);
    border-radius: var(--border-radius);
    padding: 11px 12px 10px;
  }
  &__title.spec {
    padding-right: 108px;
  }
}

.form__title {
  margin-bottom: 15px;
  input {
    background-color: var(--white-obj);
    padding-right: 108px;
  }
}

.form__btn {
  width: fit-content;
  margin-top: 15px;
}

.functions {
  position: absolute;
  top: 20px;
  right: 15px;
  transform: translateY(-50%);
  z-index: 999;
  @include flex();
  gap: 20px;
  .edit svg path {
    fill: var(--blue);
  }
  .remove svg path {
    stroke: var(--red);
  }
  svg {
    vertical-align: middle;
  }
}

.function {
  cursor: pointer;
}

.addPhotoBtn {
  border: none;
  box-shadow: var(--box-shadow);
}
