#edit-script {
  .edit-script__edit {
    margin-bottom: 28px;
  }
  .edit-script__btns {
    @include flex(flex-start);
    button {
      width: fit-content;
    }
    button:first-child {
      margin-right: 15px;
    }
  }
}