.photo__content {
	display: flex;
	flex-wrap: wrap;
}

.admin__photo {
  width: 220px;
  text-align: center;
  margin-bottom: 15px;
}

.imageWithFilter {
  width: auto;
  height: 136px;
  margin-bottom: 15px;

  &__wrapper {
    &:not(:last-child) {
      margin-right: 15px;
    }
  }

  img {
    width: 100%;
    height: inherit;
    border-radius: var(--border-radius);
    filter: drop-shadow(0px 0px 4px rgba(40, 130, 181, 0.4));
  }

  &.video {
    width: 180px;
    position: relative;
    cursor: pointer;
    .video__icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}