@import '../../../../assets/styles/static/mixins';

.block {
  @include flex(flex-start);
  max-width: 640px;
  width: 100%;
  background-color: var(--white-obj);
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
  margin-bottom: 15px;
	position: relative;
	min-height: 136px;

	.photo {
		max-width: 180px;
		width: 100%;
		height: 136px;
		cursor: pointer;
    position: relative;
    img {
      border-radius: var(--border-radius);
      border: var(--border);
      height: inherit;
    }
    input {
      position: absolute;
      opacity: 0;
      overflow: hidden;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }
	}

  .info {
    padding: 20px 20px 10px;
    max-width: 504px;
    width: 100%;
  }

	.title {
		margin-bottom: 10px;
	}

  .list {
    &__item {
      font-family: var(--R_Medium), sans-serif;
      color: var(--gray-dark);
    }
    &__item:not(:last-child) {
      margin-bottom: 10px;
    }
  }

}