.arrow {
	position: absolute;
	top: 68px;
	z-index: 999;
}

.prev {
	transform: translateY(-50%) rotate(180deg);
	left: 29px;
}

.next {
	transform: translateY(-50%);
	right: 29px;
}