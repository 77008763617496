@import '../../../../assets/styles/static/mixins';

.modal {
	height: 100%;
	width: 100%;
	position: fixed;
	z-index: 9999;
	top: 0;
	left: 0;
	background-color: rgba(#000, 0.5);
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 10px;
}

.content {
	max-width: 408px;
	width: 100%;
	padding: 15px;
	position: absolute;
	background-color: var(--white-obj);
}

.title {
	margin-bottom: 15px;
}

.buttons {
	text-align: center;
	button {
		width: fit-content;
		margin: 0 7.5px;
	}
}