.breadcrumbs {
  display: flex;
}

.item {
  opacity: 0.5;

  a {
    color: var(--gray-dark);
  }

  &:last-child {
    opacity: 1;
  }
}

.separator {
  margin: 0 7px;
}
