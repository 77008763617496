.button {
	height: 56px;
	max-width: 640px;
	width: 100%;
	font-size: 24px;
	line-height: 28px;
	color: var(--gray-dark);
	background-color: var(--white-obj);
	border-radius: var(--border-radius);
	box-shadow: var(--box-shadow);
	text-align: center;
	position: relative;
	margin-bottom: 15px;

	.backIcon {
		position: absolute;
		left: 20px;
		top: 50%;
		transform: translateY(-50%);
	}

}