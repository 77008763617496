.btns__list {
  padding: 7px;
  background-color: var(--black);
  border: var(--border);
  border-radius: var(--border-radius);
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.btns__item {
  display: inline-block;
  &:not(:first-child) {
    margin-left: 7px;
  }
}
